import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { capitalCase } from 'capital-case';
// import homeFill from '@iconify/icons-eva/home-fill';
// import personFill from '@iconify/icons-eva/person-fill';
import lockFill from '@iconify/icons-eva/lock-fill';

import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import {
  Box, Button, Divider, MenuItem, Typography
} from '@mui/material';
// components
import { MIconButton } from '../components/@material-extend';
import MenuPopover from '../components/MenuPopover';
import useAuth from '../hooks/useAuth';
import LetterAvatar from './LetterAvatar';
import { PATH_SETTINGS } from '../routes/paths';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Change Password', icon: lockFill, linkTo: PATH_SETTINGS.changePassword
  }
  // { label: 'Home', icon: homeFill, linkTo: '/' },
  // { label: 'Profile', icon: personFill, linkTo: '#' },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const user = JSON.parse(Cookies.get('UserDetails'));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <LetterAvatar firstName={user.firstName} lastName={user.lastName} />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 220
        }}
      >
        <Box sx={{
          my: 1.5, px: 2.5
        }}
        >
          <Typography variant="subtitle1" noWrap>
            {capitalCase(`${user.firstName} ${user.lastName}`)}
          </Typography>
        </Box>

        <Divider sx={{
          my: 1
        }}
        />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{
              typography: 'body2', py: 1, px: 2.5
            }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{
          p: 2, pt: 1.5
        }}
        >
          <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
