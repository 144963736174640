import axios from 'axios';

// ----------------------------------------------------------------------

const hostName = window.location.hostname;

let apiUrl = 'http://localhost:5078';

if (hostName !== 'localhost' && hostName !== '127.0.0.1') {
  // const accountId = hostNameParts[0];
  // apiUrl = `https://${accountId}.backoffice-api.gamity.io`;
  apiUrl = process.env.REACT_APP_BACKOFFICE_API_URL; // we set this in CI
}

console.log(`API URL: ${apiUrl}`);

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessage = 'Something went wrong';

    if (
      (error.response?.status === 403 || error.response?.status === 401)
      && !error.request?.responseURL.endsWith('auth/login')
    ) window.location.href = 'games';

    return Promise.reject(
      error.response && error.response.data && error.response.status !== 500 ? error.response.data : errorMessage
    );
  }
);

export default axiosInstance;
