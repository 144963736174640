// ----------------------------------------------------------------------

export default function Grid() {
  return {
    MuiGrid: {
      styleOverrides: {
      }
    }
  };
}
