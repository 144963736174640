import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoSmall.propTypes = {
  sx: PropTypes.object
};

export default function LogoSmall({ sx }) {
  return (
    <Box sx={{
      width: 40, height: 40, ...sx
    }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279.6 297.96">
        <defs>
          <style>{'.cls-1{fill:#6e50ff;}.cls-2{fill:#100066;}'}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className="cls-1"
              d="M137,225.81,67.86,294.93a1.78,1.78,0,0,0,1.26,3h32.43a16.34,16.34,0,0,0,11.54-4.78l26.53-26.54,26.49,26.53A16.35,16.35,0,0,0,177.66,298h32.43a1.78,1.78,0,0,0,1.25-3l-69.09-69.12A3.72,3.72,0,0,0,137,225.81Z"
            />
            <path
              className="cls-2"
              d="M260.08,119,233.76,92.63,142.25,1.09a3.72,3.72,0,0,0-5.26,0L20.21,117.87c-25.84,25.84-27.33,68-2,94.36a66.65,66.65,0,0,0,95.06.9l21.55-21.54a16.34,16.34,0,0,0,4.78-11.54V147.58a1.77,1.77,0,0,0-3-1.25l-18.71,18.74L91.56,191.36a35.84,35.84,0,1,1-50.71-50.67l26.33-26.33L139.62,42l98.73,98.73a35.83,35.83,0,0,1-22,61,3.7,3.7,0,0,0-3.36,3.69v23.36a3.76,3.76,0,0,0,4,3.72A66.56,66.56,0,0,0,260.08,119Z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}
