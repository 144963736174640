import { Component } from 'react';
import { Chip, Tooltip, Box } from '@mui/material';
import { DateTime } from 'luxon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

class WorldTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localTime: null,
      utcTime: null,
      costaRicaTime: null,
      maltaTime: null
    };
    this.timer = null;
  }

  componentDidMount() {
    this.updateTime();
    this.timer = setInterval(() => {
      this.updateTime();
    }, 5000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  updateTime = () => {
    const utcNow = DateTime.utc();
    const now = DateTime.now();

    this.setState({
      localTime: now.toLocaleString(DateTime.TIME_SIMPLE),
      utcTime: utcNow.toLocaleString(DateTime.TIME_SIMPLE),
      costaRicaTime: utcNow.setZone('America/Costa_Rica').toLocaleString(DateTime.TIME_SIMPLE),
      maltaTime: utcNow.setZone('CET').toLocaleString(DateTime.TIME_SIMPLE)
    });
  };

  render() {
    return (
      <Tooltip
        title={(
          <>
            <p>{`${this.state.costaRicaTime} (CST/Costa Rica)`}</p>
            <p>{`${this.state.maltaTime} (CET/Malta)`}</p>
          </>
        )}
      >
        <Chip
          label={(
            <Box sx={{
              display: 'flex', alignItems: 'center'
            }}
            >
              <AccessTimeIcon sx={{
                fontSize: '1.2rem', marginRight: '5px'
              }}
              />
              {`${this.state.localTime} (local) / ${this.state.utcTime} (UTC)`}
            </Box>
          )}
        />
      </Tooltip>
    );
  }
}

export default WorldTime;
