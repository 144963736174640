import { useState, useEffect } from 'react';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import getUserLocale from 'get-user-locale';
import enLocale from 'date-fns/locale/en-GB';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';

// ----------------------------------------------------------------------

const generateClassName = createGenerateClassName({
  seed: 'App'
});

export default function App() {
  const [locale, setLocale] = useState(enLocale);

  useEffect(() => {
    const userLocale = getUserLocale();
    const importLocaleFile = async () => {
      setLocale(enLocale);
    };

    if (locale.code !== userLocale) {
      importLocaleFile();
    }
  }, [locale.code]);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <StylesProvider generateClassName={generateClassName}>
            <NotistackProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                {/* <Settings /> */}
                <ScrollToTop />
                <Router />
              </LocalizationProvider>
            </NotistackProvider>
          </StylesProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
