// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT_AUTH = '/auth';
const ROOT_SETTINGS = '/settings';
const ROOT_CHANNELS = '/channels';
const ROOT_GAME = '/games';
const ROOT_PROVIDER = '/providers';
const ROOT_GAMESTUDIOS = '/gameStudios';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOT_AUTH,
  login: path(ROOT_AUTH, '/login'),
  forgotPassword: path(ROOT_AUTH, '/forgot-password'),
  resetPassword: path(ROOT_AUTH, '/reset-password'),
};

export const PATH_CHANNELS = {
  root: ROOT_CHANNELS,
  general: {
    channels: path(ROOT_CHANNELS, '/list'),
  },
};

export const PATH_GAME = {
  root: ROOT_GAME,
  general: {
    games: path(ROOT_GAME, '/list'),
  },
};

export const PATH_PROVIDER = {
  root: ROOT_PROVIDER,
  general: {
    providers: path(ROOT_PROVIDER, '/list'),
  },
};

export const PATH_GAMESTUDIOS = {
  root: ROOT_GAMESTUDIOS,
  general: {
    gameStudios: path(ROOT_GAMESTUDIOS, '/list'),
  },
};

export const PATH_SETTINGS = {
  root: ROOT_SETTINGS,
  changePassword: path(ROOT_SETTINGS, '/change-password'),
};
