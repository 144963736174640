// ----------------------------------------------------------------------

export default function Link() {
  return {
    MuiLink: {
      defaultProps: {
        color: '#d67e1b', // '#1a73e8', // '#5738e8', // '#2861bd', // '#4287f5',
        underline: 'hover'
      },

      styleOverrides: {
        root: {
        }
      }
    }
  };
}
