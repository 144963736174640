// material
import { styled } from '@mui/material/styles';
import {
  Box, Stack, Card, Container, Typography
} from '@mui/material';
// routes
// import useAuth from '../hooks/useAuth';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthLayout from '../layouts/AuthLayout';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();

  return (
    <RootStyle title="Login | Leofun">
      <AuthLayout />

      <MHidden width="mdDown">
        <SectionStyle>
          <img
            style={{
              maxWidth: '90%', margin: '0 auto'
            }}
            src="/static/bg1.png"
            alt="login"
          />
          <Typography
            variant="h3"
            sx={{
              px: 5, mt: 2, textAlign: 'center'
            }}
          >
            Leofun Backoffice
          </Typography>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              mb: 5
            }}
          >
            <Box sx={{
              flexGrow: 1
            }}
            >
              <Typography
                variant="h4"
                gutterBottom
                display={{
                  xs: 'none', md: 'block'
                }}
              >
                Sign in
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                display={{
                  md: 'none'
                }}
              >
                Sign in to Leofun Backoffice
              </Typography>
            </Box>

            {/* <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip> */}
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
