// routes
import DateRangeIcon from '@mui/icons-material/DateRange';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import LanguageIcon from '@mui/icons-material/Language';
import ChairIcon from '@mui/icons-material/Chair';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import SportsEsports from '@mui/icons-material/SportsEsports';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp';
import ChannelsIcon from '@mui/icons-material/Reorder';

import {
  PATH_GAME, PATH_PROVIDER, PATH_GAMESTUDIOS, PATH_CHANNELS
} from '../../routes/paths';

const ICONS = {
  channels: <ChannelsIcon />,
  calendar: <DateRangeIcon />,
  games: <CasinoOutlinedIcon />,
  currencies: <AttachMoneyIcon />,
  brands: <LanguageIcon />,
  lobby: <ChairIcon />,
  users: <PeopleAltSharpIcon />,
  quotas: <DataUsageIcon />,
  providers: <SportsEsports />,
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      {
        title: 'Games',
        path: PATH_GAME.general.games,
        icon: ICONS.games,
      },
      {
        title: 'Providers',
        path: PATH_PROVIDER.general.providers,
        icon: ICONS.providers,
      },
      {
        title: 'Game Studios',
        path: PATH_GAMESTUDIOS.general.gameStudios,
        icon: ICONS.providers,
      },
      {
        title: 'Channels',
        path: PATH_CHANNELS.general.channels,
        icon: ICONS.channels,
      },
    ],
  },
];

export default sidebarConfig;
