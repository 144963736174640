import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';

// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen sx="top: 0, left: 0, width: 1, zIndex: 9999, position: 'fixed'" />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
      ],
    },

    {
      path: 'channels',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/channels/list" replace />,
        },
        {
          path: 'list',
          element: <Channels />,
        },
        {
          path: 'new',
          element: <CreateOrEditChannelPage />,
        },
        {
          path: ':id/edit',
          element: <CreateOrEditChannelPage />,
        },
      ],
    },

    {
      path: 'games',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/games/list" replace />,
        },
        {
          path: 'list',
          element: <Games />,
        },
        {
          path: 'new',
          element: <CreateOrEditGamePage />,
        },
        {
          path: ':id/edit',
          element: <CreateOrEditGamePage />,
        },
        {
          path: ':id/clone',
          element: <CreateOrEditGamePage />,
        },
        {
          path: ':id/details',
          element: <ViewGamePage />,
        },
      ],
    },

    {
      path: 'providers',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/providers/list" replace />,
        },
        {
          path: 'list',
          element: <Providers />,
        },
        {
          path: 'new',
          element: <CreateOrEditProviderPage />,
        },
        {
          path: ':id/edit',
          element: <CreateOrEditProviderPage />,
        },
      ],
    },

    {
      path: 'gamestudios',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/gamestudios/list" replace />,
        },
        {
          path: 'list',
          element: <GameStudios />,
        },
        {
          path: 'new',
          element: <CreateOrEditGameStudioPage />,
        },
        {
          path: ':id/edit',
          element: <CreateOrEditGameStudioPage />,
        },
      ],
    },

    // Debug Routes
    {
      path: 'debug',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/games" replace />,
        },
      ],
    },

    {
      path: '/',
      element: <Navigate to="/games" replace />,
    },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/ResetPassword')));

// Channels
const Channels = Loadable(lazy(() => import('../pages/Channels')));
const CreateOrEditChannelPage = Loadable(lazy(() => import('../pages/CreateOrEditChannelPage')));
// const ViewChannelPage = Loadable(lazy(() => import('../pages/ViewChannelPage')));

// Games
const Games = Loadable(lazy(() => import('../pages/Games')));
const ViewGamePage = Loadable(lazy(() => import('../pages/ViewGamePage')));
const CreateOrEditGamePage = Loadable(lazy(() => import('../pages/CreateOrEditGamePage')));

// Providers
const Providers = Loadable(lazy(() => import('../pages/Providers')));
const CreateOrEditProviderPage = Loadable(lazy(() => import('../pages/CreateOrEditProviderPage')));

// Game Studios
const GameStudios = Loadable(lazy(() => import('../pages/GameStudios')));
const CreateOrEditGameStudioPage = Loadable(lazy(() => import('../pages/CreateOrEditGameStudioPage')));
