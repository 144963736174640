import { Avatar } from '@mui/material';
import { string } from 'prop-types';

export default function LetterAvatar(props) {
  const { firstName } = props;
  const { lastName } = props;

  return (
    <Avatar sx={{
      bgcolor: '#fd7e97', color: '#FFF'
    }}
    >
      {firstName[0].toUpperCase()}
      {lastName[0].toUpperCase()}
    </Avatar>
  );
}

LetterAvatar.propTypes = {
  firstName: string.isRequired,
  lastName: string.isRequired
};
