import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import Alert from '@mui/material/Alert';
import {
  Link, Stack, TextField, IconButton, InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import { yupResolver } from '@hookform/resolvers/yup';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { PATH_AUTH } from '../../../routes/paths';

// ----------------------------------------------------------------------

const schema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required')
});

export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loginError, setLoginError] = useState(null);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Cookies.get('passwordChanged')) {
      setPasswordChanged(true);
      if (location.pathname !== '/tournament/dashboard') navigate('/');
      else Cookies.remove('passwordChanged');
    }
  }, [passwordChanged, navigate, location]);

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setLoginError(error.message);
      }
    }
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {passwordChanged && (
          <Alert severity="success">
            Password successfully changed.
            <br />
            Use your new password to log in.
          </Alert>
        )}

        {loginError && <Alert severity="error">{loginError}</Alert>}

        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          InputLabelProps={{
            shrink: true
          }}
          {...register('email')}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          InputLabelProps={{
            shrink: true
          }}
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            )
          }}
          {...register('password')}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          my: 2
        }}
      >
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </form>
  );
}
