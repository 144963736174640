// ----------------------------------------------------------------------

export default function Container() {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
        }
      }
    }
  };
}
